import { render, staticRenderFns } from "./contacto.vue?vue&type=template&id=0dfeaf59&scoped=true&"
import script from "./contacto.vue?vue&type=script&lang=js&"
export * from "./contacto.vue?vue&type=script&lang=js&"
import style0 from "./contacto.vue?vue&type=style&index=0&id=0dfeaf59&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dfeaf59",
  null
  
)

export default component.exports