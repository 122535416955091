<template>
  <div class="blog">

    <div class="container">

      <div class="animated fadeInDown" style="padding-top: 70px;">
        <h3 style="font-size: 53px; color: #0267a6"> <strong>{{ data.contacto_title }}</strong></h3>
        <br>
        <hr>
      </div>

      <div class="col-md-12 introduccion">
        {{ data.contacto_subtitle }}
      </div>


      <br>
      <div class="col-md-12">
        <div class="row" style="opacity: 0.8;">
          <div class="col animated fadeInDown">
            <a href="https://www.facebook.com/profile.php?id=61555992661694" target="_blank">
              <img src="./../assets/img/facebook.svg" alt="" style="width: 70px; cursor: pointer;"> <br><br>
              <span> <strong>
                  Fletram
                </strong>
              </span>
            </a>
          </div>
          <div class="col animated fadeInUp">
            <a href="https://www.instagram.com/fletram_fletes/" target="_blank">
              <img src="./../assets/img/instagram.svg" href="#" alt="" style="width: 70px; cursor: pointer;"> <br><br>
              <span> <strong>
                  fletram_fletes
                </strong>
              </span>
            </a>
          </div>
          <div class="col animated fadeInDown">
            <a href="https://www.linkedin.com/company/fletram/" target="_blank">
              <img src="./../assets/img/linkedin.svg" alt="" style="width: 70px; cursor: pointer;"> <br><br>
              <span> 
                <strong>
                fletram
                </strong>
              </span>
            </a>
          </div>
          <div class="col animated fadeInUp">
            <a :href="'https://wa.me/' + whatsappNum + '?text=' + whatsappTxt " target="_blank">
              <img src="./../assets/img/whatsapp.svg" alt="" style="width: 70px; cursor: pointer;"> <br><br>
              <span> <strong>
                  {{ whatsappNumParse }}
                </strong>
              </span>
            </a>
          </div>
          <div class="col animated fadeInDown">
            <a href="mailto:fletramfletes@gmail.com">
              <img src="./../assets/img/gmail.svg" alt="" style="width: 70px; cursor: pointer;"> <br><br>
              <span> <strong>
                  fletramfletes@gmail.com
                </strong>
              </span>
            </a>
          </div>
        </div>

        <br>
        <br>
        <hr>

        <br>
        <br>
        <div class="col-md-12">
          Fletram - Celaya, Guanajuato MX
        </div>
        <br>


      </div>

    </div>


  </div>
</template>

<script>
export default {
  name: 'contacto',
  data: function () {
      return {
        data: JSON.parse(window.localStorage.getItem('lang')),
        whatsappNum: '+524611790450',
        whatsappNumParse: '461-179-0450',
        whatsappTxt: '',
      }
  },
}
</script>

<style scoped>
.blog {
  padding-top: 140px;
  padding-bottom: 40px;
}

.introduccion {
  font-size: 43px;
  font-weight: bold;
  margin-top: 50px !important;
  margin-bottom: 50px;
}
</style>
